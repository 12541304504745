<template>
  <v-app>
    <div class="main">
      <div class="box">
        <p class="box_title">云笔记后台登录</p>
        <p class="box_sub">账号登录</p>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="params.userName"
                :rules="userRule"
                placeholder="请输入账号"
                prepend-icon="mdi-account-circle-outline"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="params.password"
                :rules="passRule"
                :type="showPsw ? 'text' : 'password'"
                placeholder="请输入账号"
                prepend-icon="mdi-lock-outline"
                :append-icon="showPsw ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPsw = !showPsw"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox v-model="isCachePs" dense hide-details label="记住密码"></v-checkbox>
            </v-col>
            <v-col cols="12" class="loginBtn">
              <v-btn text x-large color="primary" @click="onSubmit" :loading="loading">登录</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      params: {
        userName: '',
        password: ''
      },
      showPsw: false,
      loading: false,
      isCachePs: true
    }
  },
  created() {
    const cachePs = this.$util.getStorage('local', 'cachePs')
    if (cachePs) {
      this.params = cachePs
    }
  },
  computed: {
    userRule() {
      return [(v) => !!v || '请输入账号']
    },
    passRule() {
      return [(v) => !!v || '请输入密码']
    }
  },
  methods: {
    async onSubmit() {
      const validate = this.$refs.form.validate()
      if (!validate) return
      this.loading = true
      const res = await this.$post('/tAdmin/login', this.params, false)
      this.loading = false
      if (res.code !== 200) return
      if (this.isCachePs) {
        this.$util.setStorage('local', 'cachePs', this.params)
      } else {
        this.$util.delStorage('local', 'cachePs')
      }
      this.$util.setStorage('local', 'token', res.data.token)
      this.$toast('登录成功')
      this.$router.push('notesList')
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  width: 100vw;
  height: 100vh;
  color: rgba(0, 0, 0, 0.87);
  background-position: center center;
  background-size: cover;
  background-image: url("https://dev-paas-ui-admin.e-buy.com/img/login.8e492b33.jpg");
  &::before {
    position: absolute;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    display: block;
    left: 0;
    top: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
  }
  .box {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    width: 300px;
    padding: 10px 20px 30px;
    background-color: #fff;
    border-radius: 10px;
    transform: translateX(-50%) translateY(-50%);
    .box_title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin-top: -40px;
      padding: 20px;
      color: #fff;
      border-radius: 10px;
      background: linear-gradient(60deg, #42a5f5, #1565c0);
    }
    .box_sub {
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
    }
    .loginBtn {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
